<template>
  <div>
    <app-menu v-model="drawer" />
    <app-toolbar
      v-model="drawer"
      @initChat="initChat"
      @initChatSuport="initChatSuport"
    />
    <v-btn
      color="primary"
      dark
      absolute
      bottom
      right
      fab
      small
      style="bottom: 10px; position: fixed"
      @click="init"
    >
      <v-icon>{{ fab ? "mdi-window-close" : "mdi-chat" }}</v-icon>
    </v-btn>
    <chatWelcome
      v-if="fab"
      @initList="initList"
      @initSuport="initSuport"
      @init="init"
    />
    <chatList
      v-if="showlist"
      @initList="initList"
      @close="close"
      @initChat="initChat"
    />
    <chatMessage
      v-if="showChat"
      @initChat="initChat"
      @close="close"
      :paramMessage="paramMessage"
      @init="init"
    />
    <chatChatSuport
      v-if="showChatSuporte"
      @initChatSuport="initChatSuport"
      @close="close"
      @initSuport="initSuport"
      @init="init"
    />
    <chatInitSuport
      v-if="showInitSuporte"
      @initSuport="initSuport"
      @initChatSuport="initChatSuport"
      @close="close"
      @init="init"
    />

    <v-row class="px-6 py-6">
      <v-col>
        <slot />
        <!-- <plan-dialog /> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import AppMenu from "../components/AppMenu.vue";
import AppToolbar from "../components/AppToolbar.vue";
import chatWelcome from "./../modules/chat/components/chatWelcome";
import chatList from "./../modules/chat/components/chalList";
import chatMessage from "./../modules/chat/components/chatMessage";
import chatInitSuport from "./../modules/chat/components/suport/chatInitSuport";
import chatChatSuport from "./../modules/chat/components/suport/chatMessage";
// import PlanDialog from "./../components/Pagination.vue";
import { CHECK_CHAT_SUPORT } from "./../modules/chat/graphql/Query";
import { MESSAGE_SENT_SUBSCRIPTION } from "./../modules/chat/graphql/subscription";
import { SEND_STATUS } from "../graphql/Query.gql";
import { mapGetters } from "vuex";
export default {
  name: "AppLayout",
  components: {
    AppMenu,
    AppToolbar,
    chatWelcome,
    chatList,
    chatMessage,
    chatInitSuport,
    chatChatSuport,
    // PlanDialog
  },
  data: () => ({
    fab: false,
    showlist: false,
    showChat: false,
    showInitSuporte: false,
    showChatSuporte: false,
    drawer: true,
    paramMessage: [],
    checkSuportMessage: [],
  }),
  apollo: {
    checkSuportEntity: {
      query: CHECK_CHAT_SUPORT,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.checkSuportEntity) {
          this.checkSuportMessage = data.checkSuportEntity;
          console.log("checkSuportEntity", this.checkSuportMessage.length);
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          //console.log(data.addChat.name,this.currentUser.id);
          if (data.addChat.name === this.currentUser.id) {
            this.checkSuportMessage = data.addChat;
          }
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    init() {
      this.fab = !this.fab;
      if (this.showlist === true) {
        this.showlist = !this.showlist;
      } else if (this.showChat === true) {
        this.showChat = !this.showChat;
      }
      if (this.showInitSuporte === true) {
        this.showInitSuporte = !this.showInitSuporte;
      } else if (this.showChatSuporte === true) {
        this.showChatSuporte = !this.showChatSuporte;
      }
    },
    initList() {
      this.fab = !this.fab;
      this.showlist = !this.showlist;
    },
    initChat(valor) {
      console.log("passando", valor);
      this.paramMessage = valor;
      // var object = [{...valor,to: valor.messages[0].to.id}]
      //  this.paramMessage = object
      this.showlist = !this.showlist;
      this.close();
      this.showChat = !this.showChat;
    },

    close() {
      this.showlist = false;
      this.fab = false;
      this.showChat = false;
      this.showInitSuporte = false;
      this.showChatSuporte = false;
    },
    initSuport() {
      this.fab = !this.fab;
      this.close();
      if (this.checkSuportMessage.length === 0) {
        this.showInitSuporte = !this.showInitSuporte;
      } else {
        this.showChatSuporte = !this.showChatSuporte;
      }
    },
    initChatSuport() {
      this.fab = !this.fab;
      this.close();
      if (this.checkSuportMessage.length === 0) {
        this.showInitSuporte = !this.showInitSuporte;
        this.showChatSuporte = !this.showChatSuporte;
      } else {
        this.showChatSuporte = !this.showChatSuporte;
      }
    },
  },
  async created() {
    // await this.loadRoles();
    await this.$apollo.mutate({
      mutation: SEND_STATUS,
      variables: {
        user_id: this.currentUser ? this.currentUser.id : undefined,
        description: "Online",
      },
    });
  },
};
</script>
