<template>
  <div>
    <!-- <pre>
     {{ currentUser.id }}
   </pre
    > -->
    <v-row class="d-flex flex-row justify-end align-center px-4 py-6">
      <v-spacer></v-spacer>
      <!-- <v-sheet class="" color="grey lighten-3" min-width="100">
        <v-row
          class="
            d-flex
            flex-row
            justify-center
            align-center
            mt-0
            px-4
            py-2
            pl-6
            pr-6
          "
        >
          <b class="">{{ currencyFormatter(totalPago) }}</b>
        </v-row>
        <v-row class="d-flex flex-row justify-center align-center mt-0 px-4">
          <p class="caption">{{ $t("TotalConfirmed") }}</p>
        </v-row>
      </v-sheet> -->
      <v-sheet class="" color="grey lighten-3 ml-2" min-width="100">
        <v-row
          class="
            d-flex
            flex-row
            justify-center
            align-center
            my-0
            px-4
            py-2
            pl-6
            pr-6
          "
        >
          <b class="">{{ currencyFormatter(totalAPagar) }}</b>
        </v-row>
        <v-row class="d-flex flex-row justify-center align-center mt-0 px-4">
          <p class="caption">{{ $t("TotalToConfirmed") }}</p>
        </v-row>
      </v-sheet>
    </v-row>
    <v-row class="d-flex flex-row justify-end align-center" style="margin-top:-6%">
      <v-col cols="12" md="8"></v-col>
      <v-col cols="12" md="4" class="mr-1">
        <v-select
          v-model="filtro"
          :items="items"
          item-text="text"
          item-value="key"
          :label="$t('filter')"
          outlined
          dense
        ></v-select>
      </v-col>
    </v-row>
    <v-sheet elevation="1">
    <v-simple-table dense fixed-header  style=" word-wrap: break-all;" height="280px">
      <template>
        <thead>
          <tr>
            <!-- <th class="text-left black--text">
              {{ $t("Cod.") }}
            </th> -->

            <th class="text-left black--text" style="width:200px">
              {{ $t("tests") }}
            </th>
            <th class="text-left black--text">
              {{ $t("Candidates") }}
            </th>
            <th class="text-left black--text">{{ $t("pUnit") }}</th>
            <th class="text-left black--text">{{ $t("vPaid") }}</th>
            <!-- <th class="text-left black--text">{{ $t("vToPaid") }}</th> -->
            <th class="text-left black--text">
              {{ $t("vConfirmed") }}
            </th>
            <th class="text-left black--text">
              {{ $t("date") }}
            </th>
            <th class="text-left black--text">{{ $t("status") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(test, j) in filteredItems" :key="j">
            <!-- <td>
              {{ test.cod }}
            </td> -->
            <td>
              {{
                test.test.title.length > 20
                  ? test.test.title.substring(0, 20) + "..."
                  : test.test.title.substring(0, 20)
              }}
            </td>
            <td>{{ test.candidates.length }}</td>
            <td>{{ currencyFormatter(test.test.price) }}</td>
            <td>
              {{
                currencyFormatter(
                  getSubTotal(test.candidates.length, test.test.price)
                )
              }}
            </td>
            <!-- <td>{{ currencyFormatter(percentage(test.test.price)) }}</td> -->
            <td>
              {{
                test.status === "paid" ? currencyFormatter(test.toConfirmed) : 0
              }}
            </td>
            <td>{{ formatDate(test.createdAt) }}</td>
            <td>
              <v-chip
                small
                :color="test.status === 'pending' ? 'error' : 'success'"
              >
                {{ $t(test.status) }}
              </v-chip>
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import moment from "moment";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  name: "TableScroll",
  props: {
    payment: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    filtro: undefined,
    array: [],
    read: true,
  }),
  mixins: [replaceSpecialCharsMixins],

  computed: {
    items() {
      return [
        { text: this.$t("Todos"), key: "" },
        { text: this.$t("pending"), key: "pending" },
        { text: this.$t("paid"), key: "paid" },
      ];
    },

    filteredItems() {
      let resultItems = this.payment;
      if (this.array.length === 0) {
        resultItems.forEach((element) => {
          console.log("element.candidates", element.candidates);
          element.tests.forEach((item) => {
            this.array.push({
              ...item,
              cod: element.cod,
              entity: element.entity,
              time: element.time,
              vacancy: element.vacancy,
              candidates: element.candidates,
              date: element.date,
              createdAt: element.createdAt,
              id: element.id,
            });
          });
        });
      }
      // console.log("array", array);
      if (this.filtro) {
        const text = this.replaceSpecialChars(this.filtro);
        return this.array.filter((el) => {
          if (this.replaceSpecialChars(el.status).includes(text)) {
            return el;
          }
        });
      }
      return this.array;
    },
    totalPago() {
      let resultItems = this.payment;
      let sum = 0;
      resultItems.forEach((element) => {
        element.tests.forEach((item) => {
          sum += item.toConfirmed;
        });
      });
      return sum;
    },
    totalAPagar() {
      let resultItems = this.payment;
      let sum = 0;
      resultItems.forEach((element) => {
        element.tests.forEach((item) => {
          if (item.status === "pending") {
            sum += item.test.price;
          }
        });
      });
      return sum;
    },
  },
  methods: {
    formatDate(date) {
      if (date) {
        moment.locale(this.$i18n.locale);
        return moment(date).format("L");
      }
    },
    currencyFormatter(price) {
      const locale = "pt-MZ";
      const currency = "MZN";
      let mtnLocale = Intl.NumberFormat(locale, {
        style: "currency",
        currency,
      });
      return mtnLocale.format(price).replace("n", "");
    },
    getSubTotal(qt, price) {
      return qt * price;
    },
    percentage(price) {
      let total = this.payment[0].candidates.length * price;
      let percentage = (total / 100) * 70;
      return percentage;
    },
    pay(test, id, totalPrice, payTest) {
      const index = this.array.findIndex((t) => t.test.id === test.test.id);
      if (index > -1) {
        this.array[index].toConfirmed = this.array[index].test.price;
        this.array[index].status = "paid";
      }
      this.$emit("sendTest", test, id, totalPrice, payTest);
    },
  },
  created() {
    // this.array[0].status = "ppppp"
  },
};
</script>

