<template>
  <v-card :color="currentModule.id === module.id ? 'grey lighten-2' : ''" outlined>
    <v-card-title>
      {{ module.name }}
    </v-card-title>

    <v-card-text>
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title>{{ module.userInterval }} Users</v-list-item-title>
          <v-list-item-title>{{ module.profileInterval }} Profiles</v-list-item-title>
          <v-list-item-subtitle>
            ...
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <v-card-actions class="pa-3">
      <v-btn 
        block
        outlined
        :color="currentModule.id === module.id ? '' : 'primary'"
        @click="$emit('selected', module)"
      >
        {{ currentModule.id === module.id ? 'CURRENT PLAN' : 'UPGRADE' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'SelectModuleCard',

  props: {
    module: { type: Object },
    currentModule: { type: Object }
  },

  data: () => ({
    modules: []
  }),

  
}
</script>