<template>
  <v-row class="d-flex flex-column justify-center" no-gutters>
    <div class="alert">
      <v-alert
        v-model="alert"
        dense
        text
        type="error"
        class="caption mb-5"
        dismissible
      >
        <div class="alert-text">{{ $t("renewal_plan_alert") }}</div>
      </v-alert>
    </div>
    <div>
      <p v-html="$t('renewal_plan')" class="mb-0 mt-10 text-info"></p>
    </div>
    <div class="payment-content">
      <v-row>
        <v-col cols="12" md="8">
          <div>
            <v-checkbox
              dense
              class="pt-0 mt-0"
              v-model="debit_current_account"
              :label="$t('debit_current_account_renewal')"
            >
              <template v-slot:label>
                <div class="text-debit_current_account">
                  {{ $t('debit_current_account_renewal') }}
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-card>
            <v-card-text class="mb-0 pb-0">
              <div class="module-name">
                {{ module.name }}
              </div>
              <!-- <v-list class="mb-0 pb-0">
                <v-divider></v-divider>
                <v-list-item class="pl-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="ml-5">Item</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle >Total</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle>Added</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-subtitle class="ml-5">Actions</v-list-item-subtitle>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item class="pl-0">
                  <v-list-item-content>
                    <v-list-item-title>Utilizador</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ currentModule.totalUsers  }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ userInterval }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="d-flex flex-row">
                      <v-btn
                        :disabled="userInterval === 0"
                        x-small
                        fab
                        dark
                        color="grey lighten-1"
                        class="mr-3"
                        @click="removeUserInterval()"
                      >
                        -
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="primary"
                        @click="addUserInterval()"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item class="pl-0 mb-0 pb-0">
                  <v-list-item-content>
                    <v-list-item-title>Candidatos</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ currentModule.totalProfiles }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ profileInterval }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="d-flex flex-row">
                      <v-btn
                        :disabled="profileInterval === 0"
                        x-small
                        fab
                        dark
                        color="grey lighten-1"
                        class="mr-3"
                        @click="removeProfileInterval()"
                      >
                        -
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="primary"
                        @click="addProfileInterval()"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list> -->
              <v-list>
                <v-divider></v-divider>
                <v-list-item class="pl-0">
                  <v-list-item-content>
                    <v-list-item-title class="module-item-title">
                      {{ userInterval }} {{ $t('users') }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="d-flex flex-row">
                      <v-btn
                        :disabled="userInterval === module.userInterval"
                        x-small
                        fab
                        dark
                        color="grey lighten-1"
                        class="mr-3"
                        @click="removeUserInterval()"
                      >
                        -
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="primary"
                        @click="addUserInterval()"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item class="pl-0">
                  <v-list-item-content>
                    <v-list-item-title class="module-item-title text-capitalize">
                      {{ profileInterval }} {{ $t('profiles') }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="d-flex flex-row">
                      <v-btn
                        :disabled="profileInterval === module.profileInterval"
                        x-small
                        fab
                        dark
                        color="grey lighten-1"
                        class="mr-3"
                        @click="removeProfileInterval()"
                      >
                        -
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="primary"
                        @click="addProfileInterval()"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-radio-group
              v-model="plan"
              hide-details
            >
              <v-row no-gutters>
                <v-col
                  v-for="p in plans"
                  :key="p.id"
                  cols="12" 
                  md="4"
                  class="plan-container"
                >
                  <div 
                    class="d-flex justify-center py-3"
                    :class="plan === p.id ? 'radio-div-selected' : 'radio-div'"
                  >
                    <v-radio
                      dense
                      class="px-10"
                      :label="`${currencyFormatter(calculateTotal(p))}/${p.name}`"
                      color="grey darken-3"
                      :value="p.id"
                    >
                      <template v-slot:label>
                        <div class="plan-total-text d-flex flex-column justfy-center align-center">
                          <div>{{currencyFormatter(calculateTotal(p))}}</div>
                          <div>{{p.name}}</div>
                        </div>
                      </template>
                    </v-radio>
                  </div>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-text>
              <div class="module-name mb-2">Checkout</div>
              <div class="d-flex flex-column">
                <v-divider></v-divider>
                <div v-if="debit_current_account" class="d-flex flex-row justify-space-between py-2 px-2">
                  <div :class="balanceAvailable <= 0 ? 'red--text' : ''">Balance available</div>
                  <div :class="balanceAvailable <= 0 ? 'red--text' : ''">{{ currencyFormatter(amount) }}</div>
                </div>
                <div v-else class="d-flex flex-row justify-space-between py-5 px-2">
                </div>
                <v-divider></v-divider>
                <div class="d-flex flex-row justify-space-between px-2 deep-orange lighten-5 payment-values-text">
                  <div>Subtotal</div>
                  <div>{{ currencyFormatter(subTotalPrice) }}</div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex flex-row justify-space-between px-2 payment-values-text">
                  <div>IVA</div>
                  <div>{{ currencyFormatter(fullPriceVAT) }}</div>
                </div>
                <div class="d-flex flex-row justify-space-between px-2 deep-orange lighten-5 payment-values-text">
                  <div>Total</div>
                  <div>{{ currencyFormatter(totalPrice) }}</div>
                </div>
                <div class="payment-finalize">
                  <v-btn
                    :disabled="(userInterval === 0 && profileInterval === 0) || (debit_current_account && balanceAvailable <= 0)"
                    color="primary"
                    block
                    @click="handleSubmit()"
                  >
                    {{ $t('finalize_payment') }}
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <error-dialog
      :dialog="showError"
      :message="error"
      @close="closeErrorDialog"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  UPGRADE_CURRENT_MODULE_MUTATION
} from '../../graphql/Mutation.gql';
import { ADD_DIALOG } from "@/mixins/dialog";
import { formatError } from '@/utils';
import ErrorDialog from '../ErrorDialog.vue';
import ProgressDialog from '../ProgressDialog.vue';
import SuccessDialog from '../SuccessDialog.vue';
export default {
  name: 'UpgradeCurrentPackage',

  components: { ErrorDialog, ProgressDialog, SuccessDialog },

  mixins: [ADD_DIALOG],

  props: {
    module: { type: Object },
    currentModule: { type: Object },
  },

  data: () => ({
    debit_current_account: false,
    plan: null,
    profileInterval: 0,
    userInterval: 0,
  }),

  created () {
    this.profileInterval = this.currentModule.totalProfiles;
    this.userInterval = this.currentModule.totalUsers;
    this.plan = this.plans[0].id;
  },

  computed: {
    ...mapGetters({
      amount: "auth/getModuleValue",
      plans: 'plan/getPlans',
    }),
    balanceAvailable () {
      return this.amount - this.totalPrice;
    },
    fullPriceVAT () {
      return this.subTotalPrice * 0.17;
    },
    subTotalPrice () {
      const selectedPlan = this.plans.find(plan => {
        return plan.id === this.plan
      })
      return this.calculateTotal(selectedPlan);
    },
    totalPrice () {
      return this.subTotalPrice + this.fullPriceVAT;
    }
  },

  methods: {
    ...mapActions({
      addModule: 'auth/addModule',
    }),
    addProfileInterval () {
      this.profileInterval += this.module.profileInterval;
    },
    addUserInterval () {
      this.userInterval += this.module.userInterval;
    },
    calculateTotal (plan) {
      let totalUser = this.userInterval * this.module.pricePerUser;
      let totalProfile = this.profileInterval* this.module.pricePerProfile;

      const total = (totalUser + totalProfile) * (plan.duration / 30);

      return total;
    },
    currencyFormatter (price) {
      const locale = 'en-US';
      const currency = 'MZN';
      let mtnLocale = Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      });
      return mtnLocale.format(price);
    },
    async handleSubmit () {
      try {
        this.isLoading = true;
        this.transaction = {
          amountPaid: this.debit_current_account ? this.totalPrice : undefined,
          module: this.module.id,
          plan: this.plan,
          status: this.debit_current_account,
          totalProfiles: this.profileInterval - this.currentModule.totalProfiles,
          totalUsers: this.userInterval - this.currentModule.totalUsers,
          value: this.totalPrice,
        };
        console.log(UPGRADE_CURRENT_MODULE_MUTATION)
        const { data } = await this.$apollo.mutate({
          mutation: UPGRADE_CURRENT_MODULE_MUTATION,
          variables: {
            id: this.currentModule.id,
            entityModuleInput: {
              ...this.transaction,
            },
          }
        });

        if (this.debit_current_account) {
          this.addModule(data.upgradeCurrentModule);
        }

        
        this.success = this.debit_current_account ? this.$t('plan_renewed_successfully') : this.$t("confirm_payment2");
        this.showSuccess = true;
        // eslint-disable-next-line no-undef
        Fire.$emit('donePackageDialog');
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true;
      } finally {
        this.isLoading = false
      }
    },
    removeProfileInterval () {
      if (this.profileInterval > 0) {
        this.profileInterval -= this.module.profileInterval; 
      }
    },
    removeUserInterval () {
      if (this.userInterval > 0) {
        this.userInterval -= this.module.userInterval;
      }
    },
  }
}
</script>

<style scoped>
  .alert {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 14%;
    right: 0;
    top: 0;
    text-align: center;
    width: 76%;
  }

  .radio-div {
    border: 1px solid #E0E0E0;
    background-color: #FFF3E0;
    padding: 10px 0;
  }

  .radio-div-selected {
    background-color: #FFE0B2;
  }

  .module-name {
    color: black;
    /* text-transform: capitalize; */
    font-weight: bold;
  }

  .payment-content {
    margin-top: 30px;
  }

  .module-item-title {
    font-size: 12px;
  }

  .payment-values-text {
    padding: 12px 0;
  }

  .payment-finalize {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  @media only screen and (max-width: 1904px) {
    * {
      font-size: 12px;
    }

    .text-header {
      font-size: 12px;
    }

    .text-debit_current_account {
      font-size: 12px !important;
    }

    .payment-content {
      margin-top: 20px;
    }

    .payment-finalize {
      padding-top: 10px;
      padding-bottom: 5px;
    }

    .module-name {
      color: black;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 12px;
    }

    .v-btn--fab.v-size--x-small .v-icon {
      height: 13px !important;
      font-size: 13px !important;
      width: 13px !important;
    }

    .v-btn--fab.v-size--x-small {
      height: 23px !important;
      font-size: 23px !important;
      width: 23px !important;
    }

    .plan-total-text {
      font-size: 12px;
      line-height: 12px;
    }

    .payment-values-text {
      padding: 8px 0;
    }
    
    .v-btn:not(.v-btn--round).v-size--default {
      height: 30px !important;
      /* min-width: 64px !important; */
      padding: 0 16px !important;
    }

    /* .v-btn.v-size--default {
      font-size: 0.875rem;
    } */
    
    .v-btn.v-size--default {
      /* font-size: 0.875rem; */
      font-size: 11px !important;
    }

    .v-alert--dense {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .alert-text {
      font-size: 12px;
    }

    .v-alert__icon.v-icon {
      font-size: 0px !important;
    }
    
    /* .v-icon.v-icon {
      font-size: 5px !important;
    } */

    .v-icon.v-icon {
      font-size: 0px !important;
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 36px;
      min-width: 64px;
      padding: 0 16px;
    }

  }
</style>