<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="70%"
  >
    <v-card>
      
      <v-tabs
        v-model="tab"
        vertical
      >
        <v-navigation-drawer
          permanent
        >
          <v-list-item class="grey lighten-4 mb-10">
            <v-list-item-content>
              <v-list-item-title class="text-h7 text-center font-weight-black">
                DEFINIÇÕES GERAIS
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-tab
            v-for="(item, index) in items"
            :key="item.title"
            class="py-2 px-4"
          >
            <v-row>
              <v-col cols="12">
                <v-card
                  :outlined="tab === index"
                  elevation="0"
                  class="py-3"
                >
                  <span class="plain-menu">{{ item.title }}</span>
                </v-card>
              </v-col>
            </v-row>
          </v-tab>
        </v-navigation-drawer>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat v-if="selectedModule === null">
              <!-- <v-card-title class="subtitle-2 text-center my-5"> -->
                <div class="d-flex flex-column my-5 mx-5">
                  <div><p v-html="$t('select_package', { module_name: lastModule.module.name })" class="mb-0"></p></div>
                  <div>
                    <p @click="openSupport()" v-html="$t('for_clarification_doubts')"></p>
                  </div>
                </div>
              <!-- </v-card-title> -->

              <v-card-text>
                <v-row>
                  <v-col cols="12" md="3" v-for="m in modules" :key="m.id">
                    <select-module-card
                      :module="m"
                      :currentModule="lastModule.module"
                      @selected="selectModule"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <change-module-card v-else :module="selectedModule" @done="dialog = false" />
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                </p>

                <p class="mb-0">
                  Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>

      
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="dialog = false"
        >Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChangeModuleCard from './ChangeModuleCard.vue';
import SelectModuleCard from './SelectModuleCard.vue';
import {
  MODULES_QUERY,
  PLANS_QUERY
} from '../graphql/Query.gql';
export default {
  name: 'PlanDialog',
  components: {
    ChangeModuleCard,
    SelectModuleCard,
  },
  data: () => ({
    column: "radio-1",
    debit_current_account: false,
    dialog: false,
    modules: [],
    tab: 0,
    plan: null,
    plans: [],
    selectedPlan: {},
    selectedModule: null,
    profileInterval: 0,
    userInterval: 0,
    transaction: {
      plan: null,
      status: true,
      totalProfiles: 0,
      totalUsers: 0,
      value: 0,
    },
  }),

  apollo: {
    plans: {
      query:   PLANS_QUERY,
      fetchPolicy: "no-cache",
    },
    modules: {
      query: MODULES_QUERY
    }
  },

  watch: {
    plans (val) {
      this.setPlans(val);
    }
  },
  
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      currentModule: "auth/getCurrentModule",
      currentModules: "auth/getModules"
    }),
    fullPriceVAT () {
      return this.subTotalPrice * 0.17; 
    },
    items () { 
      return [
        { title: 'Modulo', icon: 'mdi-help-box' },
        { title: 'Transações', icon: 'mdi-help-box' },
      ]
    },
    lastModule () {
      if (this.currentModules.length > 0) {
        return this.currentModules[this.currentModules.length - 1];
      } else {
        return this.currentModule;
      }
    }
  },

  created () {
    // eslint-disable-next-line no-undef
    Fire.$on('planDialog', async (tab) => {
      if (tab) {
        this.tab = tab;
      }
      this.dialog = true;
    })
  },

  methods: {
    ...mapActions({
      setModules: 'auth/setModules',
      updateModule: 'auth/updateModule',
      setPlans: 'plan/setPlans',
    }),
    selectModule (module) {
      this.selectedModule = module;
    },
    currencyFormatter (price) {
      const locale = 'en-US';
      const currency = 'MZN';
      let mtnLocale = Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      });
      return mtnLocale.format(price);
    },
    openSupport () {
      alert("Welcome to Support!");
    }
  }
}
</script>

<style scoped>
  .plain-menu{
    text-transform: none !important;
  }

  .text-radio {
    font-size: 8px;
  }
</style>