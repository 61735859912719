<template>
  <div>
    <v-sheet height="100%" width="750px" >
      <table-scroll :payment="payment" v-if="tab === 0" />
    </v-sheet>
  </div>
</template>
<script>
import { GET_TESTS_PAYMENT } from "./../../graphql/Query.gql";
import tableScroll from "./tableScroll.vue";

export default {
  name: "Activities",
  data() {
    return {
      mini: false,
      payment: [],
      tab: 0,
      dialog: false,
      error: "",
      showError: false,
      success: "",
      showSuccess: false,
      isLoading: false,
    };
  },
  components: {
    tableScroll,
  },
  apollo: {
    payment: {
      query: GET_TESTS_PAYMENT,
      fetchPolicy: "no-cache",
    },
  },
  payment: {
    tests: function (val) {
      if (this.appliedFilter) {
        this.setTestsState(val.tests);
        this.appliedFilter = false;
      } else {
        this.setTestsScrolled(val.tests);
      }
      this.totalPages = val.totalPages;
    },
  },
  computed: {
    menus() {
      return [
        { title: this.$t("transactions"), value: 0 },
        // { title:  this.$t('transactions'), value: 1 },
      ];
    },
  },
  methods: {
    changeMenu(position) {
      this.position = position;
      if (position === 1) {
        this.items[1].active = true;
        this.items[0].active = false;
      } else {
        this.items[0].active = true;
        this.items[1].active = false;
      }
    },
    selectMenu(tab) {
      this.tab = tab;
    },
    payDialog() {
      this.dialog = !this.dialog;
    },
  },
};
</script>
<style scoped>
.menu {
  border: 1px solid #e0e0e0;
  height: 100%;
}
.menu-items {
  height: 100%;
}
.menu-title {
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  color: #000000;
  text-transform: uppercase;
}
.menu-item {
  color: #bdbdbd;
  width: 80%;
  padding: 7px 0;
  text-align: center;
}
.menu-item:hover {
  cursor: pointer;
  color: #757575;
}
.menu-item-active {
  border: 1px solid #bdbdbd;
  color: #757575;
}
.content {
  height: 100%;
}

.v-card-content {
  min-height: 500px;
}

.btn-close {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 3px;
}

@media only screen and (max-width: 1904px) {
  .v-card-content {
    min-height: 450px;
  }

  .menu-title {
    font-size: 12px;
  }

  .menu-item {
    font-size: 12px;
  }

  .btn-close {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0px;
  }
}
</style>