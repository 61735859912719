<template>
  <v-card flat>
    <v-card-text>
      <v-row class="align-center">
        <v-col cols="12" md="12">
          <div class="d-flex flex-column">
            <div><p v-html="$t('upgrade_package')" class="mb-0"></p></div>
            <div>
              <v-checkbox
                class="pt-0 mt-0"
                v-model="debit_current_account"
                :label="$t('debit_current_account')"
              ></v-checkbox>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <v-card>
            <v-card-title>
              {{ module.name }}
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ userInterval }} Utilizador</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="d-flex flex-row">
                      <v-btn
                        :disabled="userInterval === module.userInterval"
                        x-small
                        fab
                        dark
                        color="grey lighten-1"
                        class="mr-3"
                        @click="removeUserInterval()"
                      >
                        -
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="primary"
                        @click="addUserInterval()"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ profileInterval }} Candidatos</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="d-flex flex-row">
                      <v-btn
                        :disabled="profileInterval === module.profileInterval"
                        x-small
                        fab
                        dark
                        color="grey lighten-1"
                        class="mr-3"
                        @click="removeProfileInterval()"
                      >
                        -
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="primary"
                        @click="addProfileInterval()"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
            <template>
              <v-radio-group
                v-model="plan"
                hide-details
              >
              <v-container class="pa-0" fluid>
                <v-row
                  no-gutters
                  style="flex-wrap: nowrap;"
                >
                  <v-col
                    v-for="p in plans"
                    cols="4"
                    :key="p.id"
                  >
                    <v-card
                      outlined
                      tile
                    >
                      <div class="d-flex flex-row  pa-4" :class="plan === p.id ? 'primary' : 'deep-orange lighten-5'">
                        <div>
                          <v-radio
                            color="grey darken-3"
                            :label="`${currencyFormatter(calculateTotal(p))} / ${p.name}`"
                            :value="p.id"
                          ></v-radio>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
                </v-radio-group>
            </template>
          </v-card>
        </v-col>

        <v-col cols="12" md="4">
          <v-card>
            <v-card-title>
              CHECK OUT
            </v-card-title>
            <v-card-text>
              <div class="d-flex flex-column">
                <v-divider
                ></v-divider>
                <div v-if="debit_current_account" class="d-flex flex-row justify-space-between py-3 px-2">
                  <div :class="balanceAvailable <= 0 ? 'red--text' : ''">Balance available</div>
                  <div :class="balanceAvailable <= 0 ? 'red--text' : ''">{{ currencyFormatter(balanceAvailable) }}</div>
                </div>
                <div v-else class="d-flex flex-row justify-space-between py-5 px-2">
                </div>
                <v-divider
                ></v-divider>
                <div class="d-flex flex-row justify-space-between py-3 px-2 deep-orange lighten-5">
                  <div>Subtotal</div>
                  <div>{{ currencyFormatter(subTotalPrice) }}</div>
                </div>
                <v-divider
                ></v-divider>
                <div class="d-flex flex-row justify-space-between py-3 px-2">
                  <div>IVA</div>
                  <div>{{ currencyFormatter(fullPriceVAT) }}</div>
                </div>
                <v-divider
                ></v-divider>
                <div class="d-flex flex-row justify-space-between py-3 px-2 deep-orange lighten-5">
                  <div>Total</div>
                  <div>{{ currencyFormatter(totalPrice) }}</div>
                </div>
                <div>
                  <v-btn
                    :disabled="debit_current_account && balanceAvailable <= 0"
                    color="primary"
                    block
                    class="mt-3"
                    @click="handleSubmit()"
                  >
                    Finalizar pagamento
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  CHANGE_ENTITY_MODULE_MUTATION
} from '../graphql/Mutation.gql';
import { formatError } from '@/utils';
export default {
  name: 'ChangeModuleCard',

  props: {
    module: { type: Object }
  },

  data: () => ({
    debit_current_account: false,
    plan: null,
    profileInterval: 0,
    userInterval: 0,
  }),

  created () {
    this.profileInterval = this.module.profileInterval;
    this.userInterval = this.module.userInterval;
    this.plan = this.plans[0].id;
  },

  computed: {
    ...mapGetters({
      amount: "auth/getModuleValue",
      plans: 'plan/getPlans',
    }),
    balanceAvailable () {
      return this.amount - this.totalPrice;
    },
    fullPriceVAT () {
      return this.subTotalPrice * 0.17;
    },
    subTotalPrice () {
      const selectedPlan = this.plans.find(plan => {
        return plan.id === this.plan
      })
      return this.calculateTotal(selectedPlan);
    },
    totalPrice () {
      return this.subTotalPrice + this.fullPriceVAT;
    }
  },

  methods: {
    addProfileInterval () {
      this.profileInterval += this.module.profileInterval;
    },
    addUserInterval () {
      this.userInterval += this.module.userInterval;
    },
    calculateTotal (plan) {
      let totalUser = this.userInterval * this.module.pricePerUser;
      let totalProfile = this.profileInterval * this.module.pricePerProfile;

      const total = (totalUser + totalProfile) * (plan.duration / 30);

      return total;
    },
    currencyFormatter (price) {
      const locale = 'en-US';
      const currency = 'MZN';
      let mtnLocale = Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      });
      return mtnLocale.format(price);
    },
    async handleSubmit () {
      try {
        this.transaction = {
          module: this.module.id,
          plan: this.plan,
          status: false,
          totalProfiles: this.profileInterval,
          totalUsers: this.userInterval,
          value: this.totalPrice,
        };
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: CHANGE_ENTITY_MODULE_MUTATION,
          variables: {
            entityModuleInput: {
              ...this.transaction,
            },
          }
        });
        
        this.$emit('done');
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    removeProfileInterval () {
      if (this.profileInterval !== this.module.profileInterval) {
        this.profileInterval -= this.module.profileInterval; 
      }
    },
    removeUserInterval () {
      if (this.userInterval !== this.module.userInterval) {
        this.userInterval -= this.module.userInterval;
      }
    },
  }
}
</script>