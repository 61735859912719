<template>
  <!-- <v-row> -->
    <!-- <div>
      <v-card v-if="$apollo.queries.transactions.loading">
        <v-skeleton-loader
          :type="
            `table-thead, table-row-divider@5, table-tfoot`
          "
        ></v-skeleton-loader>
      </v-card>
      <v-card v-else class="px-4">
        <v-card-title>
          Tabela geral de pagamentos
        </v-card-title>
        <finance-table :transactions="transactions"/>
        <PaymnetDialog /> 
      </v-card>
    </div>   -->
    <v-col class="d-flex flex-column justify-center align-center">
      <v-card v-if="$apollo.queries.transactions.loading">
        <v-skeleton-loader
          :type="
            `table-thead, table-row-divider@5, table-tfoot`
          "
        ></v-skeleton-loader>
      </v-card>
      <v-card v-else style="width: 100%">
        <finance-table :transactions="transactions"/>
        <PaymnetDialog /> 
      </v-card>
    </v-col>
  <!-- </v-row> -->
</template>

<script>
import {
  GET_TRANSACTIONS_BY_ENTITY
} from '../../graphql/Query.gql'
import FinanceTable from './FinanceTable.vue';
export default {
  name: 'Transaction',

  components: {
    FinanceTable
  },

  data: () => ({
    transactions: [],
  }),

  apollo: {
    transactions: {
      query: GET_TRANSACTIONS_BY_ENTITY,
      fetchPolicy: 'no-cache'
    }
  },
}
</script>