<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="transactions"
      :items-per-page="-1"
      fixed-header
      dense
      height="360px"
      hide-default-footer
    >
      <template v-slot:[`item.package`]="{ item }">
        <div class="text-start" :key="item.id">
          {{ item.module.name }}
        </div>
      </template>
      <template v-slot:[`item.amountPaid`]="{ item }">
        <div class="text-end" :key="item.id">
          {{ formatCurrency(item.amountPaid) }}
        </div>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <div class="text-center" :class="dateIsAfter(addDaysToDate({ date: item.date, days: item.plan.duration})) ? '' : 'error--text'" :key="item.id">
          {{ dateFormat(item.date) }}
        </div>
      </template>
      <template v-slot:[`item.dueDate`]="{ item }">
        <div class="text-center" :class="dateIsAfter(addDaysToDate({ date: item.date, days: item.plan.duration})) ? '' : 'error--text'" :key="item.id">
          {{ dateFormat(addDaysToDate({ date: item.date, days: item.plan.duration})) }}
        </div>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <div class="text-end" :key="item.id">
          {{ formatCurrency(item.value) }}
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div class="d-flex justify-center align-center text-center" :key="item.id">
          <div v-if="item.amountPaid && item.amountPaid > 0" class="status status-confirmed success text-capitalize">
            <span class="white--text text-capitalize">{{ $t('payment_regularized') }}</span>
          </div>
          <div v-else-if="!dateIsAfter(addDaysToDate({ date: item.date, days: item.plan.duration}))" class="status status-pending error text-capitalize">
            <span class="white--text text-capitalize">{{ $t('expired')}}</span>
          </div>
          <div v-else class="status status-pending warning text-capitalize">
            <span class="white--text text-capitalize">{{ $t('payment_not_regularized')}}</span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-right" :key="item.id">
          <v-btn
            color="primary"
            x-small
            height="30px"
            class="ma-1"
            dark
            @click="openPaymentDialog(item)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </div>
</template>

<script>
import {
  UPDATE_ENTITY_MODULE_STATUS_MUTATION
} from '../../graphql/Mutation.gql';
import addDaysToDateMixins from '@/mixins/add-days-to-date';
import compareDatesMixins from '@/mixins/compare-dates';
import formatCurrencyMixins from "@/mixins/format-currency";
import dateFormatMixins from "@/mixins/date-format";
import { formatError } from '@/utils'
import ErrorDialog from './../ErrorDialog.vue'
import ProgressDialog from './../ProgressDialog.vue'
import SuccessDialog from './../SuccessDialog.vue'
export default {
  name: 'FinanceTable',
  components: { ErrorDialog, ProgressDialog, SuccessDialog },
  props: {
    transactions: {
      type: Array
    }
  },
  mixins: [addDaysToDateMixins, compareDatesMixins, dateFormatMixins, formatCurrencyMixins],
  data: () => ({
    error: undefined,
    isLoading: false,
    showError: false,
    showSuccess: false,
    success: undefined,
  }),
  computed: {
    headers () {
      return [
        // { text: "Código", align: "start", sortable: false, value: "code" },
        { text: this.$t('Module'), align: "start", sortable: false, value: "package" },
        { text: `Nº ${this.$t('users')}`, align: "center", sortable: false, value: "totalUsers" },
        { text: `Nº ${this.$t('Candidates')}`, align: "center", sortable: false, value: "totalProfiles" },
        { text: this.$t('amount_paid'), align: "end", sortable: false, value: "amountPaid" },
        { text: this.$t('Subscription_date'), align: "center", sortable: false, value: "date" },
        { text: this.$t('Due_date'), align: "center", sortable: false, value: "dueDate" },
        // { text: "Valor a Pagar", align: "end", value: "value" },
        { text: this.$t('payment_status'), align: "center", sortable: false, value: "status" },
        // { text: "Acções", align: "right", value: "actions", sortable: false }
      ];
    }
  },

  methods: {
    openPaymentDialog (transaction) {
      // eslint-disable-next-line no-undef
      Fire.$emit('paymentDialog', transaction);
    },
    async updateTransactionStatus (transaction) {
      try {
        this.isLoading = true;
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_ENTITY_MODULE_STATUS_MUTATION,
          variables: {
            id: transaction.id,
            status: transaction.status,
          }
        });
        
        // eslint-disable-next-line no-undef
        Fire.$emit('updateTransaction', data.updateEntityModuleStatus);
        this.dialog = false;
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped>
  .status {
    border: 1px solid black;
    width: 100px;
    font-size: 11px;
    font-weight: bold;
    padding: 0 2px;
    border-radius: 5px;
  }

  @media only screen and (max-width: 1904px) {
    * {
      font-size: 12px;
    }
    .status {
      width: 100px;
    }
  }
</style>