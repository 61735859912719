<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="70%"
  >
    <v-card class="d-flex v-card-content">
      <!-- <v-card-text class="pa-0"> -->
        <v-row no-gutters>
          <v-col cols="12" md="2" lg="2">
            <div class="d-flex flex-column justify-center menu">
              <div class="menu-title d-flex justify-center py-3" >
                {{ $t('settings') }}
              </div>
              <div class="menu-items d-flex flex-column align-center mt-12 capitalize">
                <div
                  v-for="menu in menus"
                  :key="menu.value"
                  class="menu-item text-capitalize"
                  :class="{ 'menu-item-active': menu.value === tab }"
                  @click="selectMenu(menu.value)"
                >
                  {{ menu.title }}
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="10" lg="10">
            <v-row class="d-flex px-5 content" no-gutters>
              <select-module v-if="tab === 0" :module_about_to_expire="module_about_to_expire"/>
              <update-package v-else-if="tab === 1" />
              <transaction v-else-if="tab === 2" />
              <test-transaction v-else />
              <v-btn
                class="btn-close"
                icon
                @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn>
            </v-row>
          </v-col>
          
        </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectModule from './SelectModule.vue';
import Transaction from './Transaction.vue';
import UpdatePackage from './UpdatePackage.vue';
import TestTransaction from './Activity.vue';
export default {
  name: 'Package',

  components: {
    SelectModule,
    Transaction,
    UpdatePackage,
    TestTransaction
  },
  
  data: () => ({
    dialog: false,
    tab: 0,
    module_about_to_expire: ''
  }),

  created () {
    // eslint-disable-next-line no-undef
    Fire.$on('packageDialog', async (tab) => {
      this.tab = 0;
      if (tab) {
        this.tab = tab;
      }
      this.dialog = true;
    })
    // eslint-disable-next-line no-undef
    Fire.$on('packageDialogCloseToExpire', async (module_about_to_expire) => {
      this.tab = 0;
      this.module_about_to_expire = module_about_to_expire
      this.dialog = true;
    })

    // eslint-disable-next-line no-undef
    Fire.$on('donePackageDialog', () => {
      this.dialog = false
    });
  },

  computed: {
    menus () {
      return [
        { title: this.$t('current_plan'), value: 1 },
        { title:  this.$t('modules'), value: 0 },
        { title:  this.$t('transactions'), value: 2 },
        { title:  this.$t('testtransactions'), value: 3 },
      ]
    }
  },

  methods: {
    selectMenu (tab) {
      this.tab = tab;
    }
  }
}
</script>

<style scoped>
  .menu {
    border: 1px solid #E0E0E0;
    height: 100%;
  } 
  .menu-items {
    height: 100%;
  }
  .menu-title {
    background-color: #E0E0E0;
    border: 1px solid #E0E0E0;
    color: #000000;
    text-transform: uppercase;
  }

  .menu-item {
    color: #BDBDBD;
    width: 80%;
    padding: 7px 0;
    text-align: center;
  }

  .menu-item:hover {
    cursor: pointer;
    color: #757575;
  }

  .menu-item-active {
    border: 1px solid #BDBDBD;
    color: #757575;
  }

  .content {
    height: 100%;
  }

  .v-card-content {
    min-height: 500px;
  }

  .btn-close {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 3px;
  }

  @media only screen and (max-width: 1904px) {
    .v-card-content {
      min-height: 450px;
    }

    .menu-title {
      font-size: 12px;
    }

    .menu-item {
      font-size: 12px;;
    }

    .btn-close {
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 0px;
    }
  }
</style>