<template>
  <v-row v-if="!selectedModule" class="d-flex flex-column justify-center" no-gutters>
    <div class="text-header">
      <p v-html="$t('select_package', { module_name: modulesNames })" class="mb-0"></p>
    </div>
    <div class="text-support">
      <p @click="openSupport()" v-html="$t('for_clarification_doubts')"></p>
    </div>
    <div class="mt-10">
      <v-row>
        <v-col
          v-for="m in modules"
          :key="m.id" 
          cols="12" 
          md="3"
        >
          <v-card outlined class="card-module">
            <v-card-text>
              <div class="card-content d-flex flex-column justify-space-between align-stretch">
                <div class="card-title">
                  <h3>{{ m.name }}</h3>
                </div>
                <div>
                  <div class="card-text-user">{{ m.userInterval }} {{$t('user')}}</div>
                  <div class="card-text-profiles">{{ m.profileInterval }} {{$t('profiles')}}</div>
                </div>
                <div class="d-flex flex-column">
                  <v-btn
                    class="btn-upgrade"
                    outlined
                    block
                    :color="isCurrentPackage(m.id) ? '' : 'primary'"
                    @click="selectPackage(m)"
                  >
                    {{ isCurrentPackage(m.id) ? $t('renew_plan') : $t('subscribe') }}
                  </v-btn>
                </div>
              </div>
            </v-card-text> 
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-row>
  <upgrade-current-package v-else-if="isCurrentPackage(selectedModule.id)" :module="selectedModule" :currentModule="getCurrentPackage()" />
  <upgrade-package v-else :module="selectedModule" />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  MODULES_QUERY,
  PLANS_QUERY
} from '../../graphql/Query.gql';
import UpgradeCurrentPackage from './UpgradeCurrentPackage.vue';
import UpgradePackage from './UpgradePackage.vue';
export default {
  name: 'SelectModule',

  props: {
    module_about_to_expire: {type: String}
  },

  data: () => ({
    modules: [],
    plans: [],
    selectedModule: null,
  }),

  apollo: {
    plans: {
      query:   PLANS_QUERY,
      fetchPolicy: "no-cache",
    },
    modules: {
      query: MODULES_QUERY
    }
  },

  watch: {
    plans (val) {
      this.setPlans(val);
    },

    module_about_to_expire(val) {
      this.selectedModule = this.modules.find((findModule) => findModule.name === val)
      console.log(this.selectedModule)
    }
  },

  components: {
    UpgradeCurrentPackage,
    UpgradePackage
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      currentModule: "auth/getCurrentModule",
      currentModules: "auth/getModules"
    }),
    lastModule () {
      if (this.currentModules.length > 0) {
        return this.currentModules[this.currentModules.length - 1];
      } else {
        return this.currentModule;
      }
    },
    modulesNames () {
      let names = '';
      const arrayOfNames = this.currentModules.map(module => (module.module.name));
      const arrayOfNamesFilter = arrayOfNames.filter((name, index) => {
        return arrayOfNames.indexOf(name) === index
      });

      if (arrayOfNamesFilter.length > 1) {
        for (let index = 0; index < arrayOfNamesFilter.length; index++) {
          if (index === arrayOfNamesFilter.length - 1) {
            const locale = localStorage.getItem("lang");
            if (locale === 'en') {
              names += `and ${arrayOfNamesFilter[index]}`
            } else {
              names += `e ${arrayOfNamesFilter[index]}`
            }
          } else {
            names += `${arrayOfNamesFilter[index]}, `;
          }
        }
      } else {
        names = arrayOfNamesFilter[0];
      }

      return names;
    }
  },

  methods: {
    ...mapActions({
      setModules: 'auth/setModules',
      updateModule: 'auth/updateModule',
      setPlans: 'plan/setPlans',
    }),
    openSupport () {
      alert("Welcome to Support!");
    },
    selectPackage (module) {
      this.selectedModule = module;
    },
    isCurrentPackage (id) {
      const index = this.currentModules.findIndex(currentModule => currentModule.module.id === id);
      return index > -1;
    },
    getCurrentPackage () {
      const modules = this.currentModules.filter(currentModule => {
        return currentModule.module.id === this.selectedModule.id
      });

      console.log("modules: ", modules)
      
      const totalUsers = modules.reduce((accumulate, module) => {
        return accumulate + module.totalUsers;
      }, 0);

      const totalProfiles = modules.reduce((accumulate, m) => {
        return accumulate + m.totalProfiles;
      }, 0);

      console.log("totalProfiles: ", totalProfiles)

      return { totalUsers, totalProfiles, name: this.selectedModule.name, id: this.currentModules[this.currentModules.length - 1].id };
    }
  }
}
</script>

<style scoped>
  .container {
    height: 100%;
  }

  .card-module {
    height: 220px;
  }

  .card-title {
    color: black;
    font-weight: bold;
  }

  .v-card__text {
    height: 100%;
  }

  .card-content {
    height: 100%;
  }

  @media only screen and (max-width: 1904px) {
    .text-header {
      font-size: 12px;
    }

    .text-support {
      font-size: 12px;
    }

    .card-module {
      height: 180px;
    }

    .card-title {
      color: black;
      font-weight: bold;
      font-size: 10px;
    }

    .card-text-user, .card-text-profiles {
      font-size: 11px;
    }

    .btn-upgrade {
      height: 20px !important;
      padding: 0 8.8888888889px !important;
      font-size: 11px;
    }
  }
</style>