<template>
  <v-row class="d-flex flex-column justify-center" no-gutters>
    <div class="alert">
      <v-alert
        v-model="alert"
        dense
        text
        type="error"
        class="caption mb-5"
        dismissible
      >
        <div class="alert-text">{{ $t("confirm_parment") }}</div>
      </v-alert>
    </div>
    <div>
      <p v-html="$t('update_package')" class="mb-0 mt-10 text-info"></p>
    </div>
    <div class="payment-content">
      <v-row>
        <v-col cols="12" md="8">
          <div>
            <v-checkbox
              dense
              class="pt-0 mt-0"
              v-model="debit_current_account"
              :label="$t('debit_current_account')"
            >
              <template v-slot:label>
                <div class="text-debit_current_account">
                  {{ $t('debit_current_account') }}
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-card>
            <v-card-text class="mb-0 pb-0">
              <div class="module-name">
                {{ modulesNames }}
              </div>
              <v-list class="mb-0 pb-0">
                <v-divider></v-divider>
                <v-list-item class="pl-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="ml-5">Item</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle >Total</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content>
                    <v-list-item-subtitle class="text-capitalize">{{ $t('to_add') }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-list-item-subtitle class="ml-5">{{ $t('actions') }}</v-list-item-subtitle>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item class="pl-0">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('users') }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ totalUsers  }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ userInterval }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="d-flex flex-row">
                      <v-btn
                        :disabled="userInterval === 0"
                        x-small
                        fab
                        dark
                        color="grey lighten-1"
                        class="mr-3"
                        @click="removeUserInterval()"
                      >
                        -
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="primary"
                        @click="addUserInterval()"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item class="pl-0 mb-0 pb-0">
                  <v-list-item-content>
                    <v-list-item-title class="text-capitalize">{{ $t('profiles') }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ totalProfiles }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content>
                    <v-list-item-title>{{ profileInterval }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="d-flex flex-row">
                      <v-btn
                        :disabled="profileInterval === 0"
                        x-small
                        fab
                        dark
                        color="grey lighten-1"
                        class="mr-3"
                        @click="removeProfileInterval()"
                      >
                        -
                      </v-btn>
                      <v-btn
                        x-small
                        fab
                        dark
                        color="primary"
                        @click="addProfileInterval()"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-radio-group
              v-model="plan"
              hide-details
            >
              <v-row no-gutters>
                <v-col
                  v-for="p in plans"
                  :key="p.id"
                  cols="12" 
                  md="4"
                  class="plan-container"
                >
                  <div 
                    class="d-flex justify-center py-3"
                    :class="plan === p.id ? 'radio-div-selected' : 'radio-div'"
                  >
                    <v-radio
                      disabled
                      class="px-10"
                      :label="`${currencyFormatter(calculateTotal(p))}/${p.name}`"
                      color="grey darken-3"
                      :value="p.id"
                    >
                      <template v-slot:label>
                        <div class="plan-total-text d-flex flex-column justfy-center align-center">
                          <div>{{currencyFormatter(calculateTotal(p))}}</div>
                          <div>{{p.name}}</div>
                        </div>
                      </template>
                    </v-radio>
                  </div>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-text>
              <div class="module-name mb-2">Checkout</div>
              <div class="d-flex flex-column">
                <v-divider></v-divider>
                <div v-if="debit_current_account" class="d-flex flex-row justify-space-between py-2 px-2">
                  <div :class="balanceAvailable <= 0 ? 'red--text' : ''">Balance available</div>
                  <div :class="balanceAvailable <= 0 ? 'red--text' : ''">{{ currencyFormatter(amount) }}</div>
                </div>
                <div v-else class="d-flex flex-row justify-space-between py-5 px-2">
                </div>
                <v-divider></v-divider>
                <div class="d-flex flex-row justify-space-between px-2 deep-orange lighten-5 payment-values-text">
                  <div>Subtotal</div>
                  <div>{{ currencyFormatter(subTotalPrice) }}</div>
                </div>
                <v-divider></v-divider>
                <div class="d-flex flex-row justify-space-between px-2 payment-values-text">
                  <div>IVA</div>
                  <div>{{ currencyFormatter(fullPriceVAT) }}</div>
                </div>
                <div class="d-flex flex-row justify-space-between px-2 deep-orange lighten-5 payment-values-text">
                  <div>Total</div>
                  <div>{{ currencyFormatter(totalPrice) }}</div>
                </div>
                <div class="payment-finalize">
                  <v-btn
                    :disabled="(userInterval === 0 && profileInterval === 0) || (debit_current_account && balanceAvailable <= 0)"
                    color="primary"
                    block
                    @click="handleSubmit()"
                  >
                    {{ $t('finalize_payment') }}
                  </v-btn>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <error-dialog
      :dialog="showError"
      :message="error"
      @close="closeErrorDialog"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  UPDATE_ENTITY_MODULE_MUTATION
} from '../../graphql/Mutation.gql';
import { ADD_DIALOG } from "@/mixins/dialog";
import { formatError } from '@/utils';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import ErrorDialog from '../ErrorDialog.vue';
import ProgressDialog from '../ProgressDialog.vue';
import SuccessDialog from '../SuccessDialog.vue';
export default {
  name: 'UpdatePackage',

  components: { ErrorDialog, ProgressDialog, SuccessDialog },

  mixins: [ADD_DIALOG],

  data: () => ({
    debit_current_account: false,
    plan: null,
    profileInterval: 0,
    transaction: {
      plan: null,
      status: true,
      totalProfiles: 0,
      totalUsers: 0,
      value: 0,
    },
    userInterval: 0,
  }),
  
  created () {
    this.plan = this.modules[this.lastModules].plan.id;
  },

  computed: {
    ...mapGetters({
      amount: "auth/getModuleValue",
      currentUser: "auth/getCurrentUser",
      modules: "auth/getModules",
      plans: 'plan/getPlans',
    }),
    balanceAvailable () {
      return this.amount - this.totalPrice;
    },
    fullPriceVAT () {
      return this.subTotalPrice * 0.17;
    },
    lastModules () {
      return this.modules.length - 1
    },
    mainTransaction () {
      const transactions = this.modules.filter(findedModule => this.modules[this.lastModules].module.name === findedModule.module.name);
      let main = this.modules[0];
      for (let index = 0; index < transactions.length; index++) {
        if (transactions[index].value > main.value ) {
          main = transactions[index];
        }
      }

      return main;
    },
    modulesNames () {
      let names = '';
      const arrayOfNames = this.modules.map(module => (module.module.name));
      const arrayOfNamesFilter = arrayOfNames.filter((name, index) => {
        return arrayOfNames.indexOf(name) === index
      });

      if (arrayOfNamesFilter.length > 1) {
        for (let index = 0; index < arrayOfNamesFilter.length; index++) {
          if (index === arrayOfNamesFilter.length - 1) {
            const locale = localStorage.getItem("lang");
            if (locale === 'en') {
              names += `and ${arrayOfNamesFilter[index]}`
            } else {
              names += `e ${arrayOfNamesFilter[index]}`
            }
          } else {
            names += `${arrayOfNamesFilter[index]}, `;
          }
        }
      } else {
        names = arrayOfNamesFilter[0];
      }

      return names;
    },
    subTotalPrice () {
      const selectedPlan = this.plans.find(plan => {
        return plan.id === this.plan
      })
      return this.calculateTotal(selectedPlan);
    },
    totalPrice () {
      return this.subTotalPrice + this.fullPriceVAT;
    },
    totalProfiles () {
      return this.modules.reduce((accumulator, currentModule) => {
        return accumulator + currentModule.totalProfiles;
      }, 0);
    },
    totalUsers () {
      return this.modules.reduce((accumulator, currentModule) => {
        return accumulator + currentModule.totalUsers;
      }, 0);
    }
  },

  methods: {
    ...mapActions({
      addModule: 'auth/addModule',
    }),
    addUserInterval () {
      this.userInterval += this.modules[this.lastModules].module.userInterval;
    },
    addProfileInterval () {
      this.profileInterval += this.modules[this.lastModules].module.profileInterval;
    },
    calculateTotal (plan) {
      let totalUser = this.userInterval * this.modules[this.lastModules].module.pricePerUser;
      let totalProfile = this.profileInterval * this.modules[this.lastModules].module.pricePerProfile;
      let total = 0;

      if (this.debit_current_account) {
        const distance = formatDistanceToNowStrict(new Date(this.mainTransaction.date));
        const distanceSplited = distance.split(" ");
  
        switch (distanceSplited[1]) {
          case "months":
            total = (totalUser + totalProfile) * ((plan.duration / 30) - Number(distanceSplited[0]));
            break;

          case "year":
            total = (totalUser + totalProfile) * ((plan.duration / 30) - (Number(distanceSplited[0]) * 12));
            break;

          default:
            if (Number(distanceSplited[0]) >= 28) {
              total = (totalUser + totalProfile) * ((plan.duration / 30) - 1);
            } else {
              total = (totalUser + totalProfile) * (plan.duration / 30);
            }
            break;
        }
      } else {
        total = (totalUser + totalProfile) * (plan.duration / 30);
      }

      return total;
    },
    currencyFormatter (price) {
      const locale = 'en-US';
      const currency = 'MZN';
      let mtnLocale = Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      });
      return mtnLocale.format(price);
    },
    async handleSubmit () {
      try {
        this.isLoading = true;
        this.transaction = {
          plan: this.plan,
          status: true,
          totalProfiles: this.profileInterval,
          totalUsers: this.userInterval,
          value: this.totalPrice,
          amountPaid: this.debit_current_account ? this.totalPrice : undefined,
        };
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_ENTITY_MODULE_MUTATION,
          variables: {
            id: this.modules[this.lastModules].id,
            entityModuleInput: {
              ...this.transaction,
            },
          }
        });

         if (this.debit_current_account) {
          this.addModule(data.changeEntityModule);
        }
        
        // this.updateModule(data.updateEntityModule);
        this.success = this.debit_current_account ? this.$t('updated_success', { item: this.$t('plan') }) : this.$t("confirm_payment2");
        this.showSuccess = true;
        // eslint-disable-next-line no-undef
        Fire.$emit('donePackageDialog');
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    removeUserInterval () {
      if (this.userInterval > 0) {
        this.userInterval -= this.modules[this.lastModules].module.userInterval;
      }
    },
    removeProfileInterval () {
      if (this.profileInterval > 0) {
        this.profileInterval -= this.modules[this.lastModules].module.profileInterval; 
      }
    },
  }
}
</script>

<style scoped>
  .alert {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 14%;
    right: 0;
    top: 0;
    text-align: center;
    width: 76%;
  }

  .radio-div {
    border: 1px solid #E0E0E0;
    background-color: #FFF3E0;
  }

  .radio-div-selected {
    background-color: #FFE0B2;
  }

  .module-name {
    color: black;
    /* text-transform: capitalize; */
    font-weight: bold;
  }

  .payment-content {
    margin-top: 30px;
  }

  .module-item-title {
    font-size: 12px;
  }

  .payment-values-text {
    padding: 12px 0;
  }

  .payment-finalize {
    padding-top: 30px;
    padding-bottom: 25px;
  }

  @media only screen and (max-width: 1904px) {
    * {
      font-size: 12px;
    }

    .v-alert--dense {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    .alert-text {
      font-size: 12px;
    }

    .payment-content {
      margin-top: 20px;
    }

    .text-debit_current_account {
      font-size: 12px !important;
    }

    .v-btn--fab.v-size--x-small .v-icon {
      height: 13px !important;
      font-size: 13px !important;
      width: 13px !important;
    }

    .v-btn--fab.v-size--x-small {
      height: 23px !important;
      font-size: 23px !important;
      width: 23px !important;
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 30px !important;
      /* min-width: 64px !important; */
      padding: 0 16px !important;
    }

    .v-btn.v-size--default {
      /* font-size: 0.875rem; */
      font-size: 11px !important;
    }

    .plan-total-text {
      font-size: 12px;
      line-height: 12px;
    }

    .payment-values-text {
      padding: 8px 0;
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 36px;
      min-width: 64px;
      padding: 0 16px;
    }
  }

  /* .text-info {
    margin-top: 30px;
  } */
</style>