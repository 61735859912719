<template>
  <app-layout>
    <router-view />
  </app-layout>
</template>

<script>
import { mapGetters } from "vuex";
import AppLayout from "./../layouts/AppLayout";
export default {
  name: "Dashboard",
  components: {
    AppLayout,
  },
  data: () => ({
    alertDialog: true,
    alertMessage: 'Confirme a sua subscrição para poder continuar. Envie-nos o comprovativo de transferência para o email pagamentos@whirelab.com. Para esclarecimento de dúvidas, por favor contacte nossa equipe de suporte.'
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  }
};
</script>
